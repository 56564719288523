@import "./reset";
@import "./site-elems";
@import "./header";

body {
  width: 100%;
  min-width: 32rem;
  overflow-x: hidden;
}

.wrapper {
  overflow-x: hidden;
  max-width: 192rem;
  margin: 0 auto;
  width: 100%;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 2rem;
  @media (max-width: 425px) {
    padding: 0 1rem;
  }
}

.main {
  position: relative;
  margin-top: 25rem + 7rem;
  background-color: #fff;
  z-index: 2;
  @media (max-width: 768px) {
    margin-top: 58rem + 7rem;
  }
}

@import "./header__underblock";
@import "./how-it-work";
@import "./what-for";
@import "./try-it";
@import "./get-badge";
@import "./contacts";
@import "./variants";