.how-it-work {
  margin-top: 10rem;
  .how-it-work__text {
    margin-top: 9.5rem;
    text-align: center;
    padding: 4.4rem 7.2rem;
    background: #ffffff;
    box-shadow: 0px 6px 54px rgba(218, 225, 231, 0.53);
    border-radius: 5.7rem;
    img {
      width: 10.8rem;
      height: auto;
      vertical-align: middle;
      margin: 0 0.75rem;
    }
    @media (max-width: 768px) {
      margin-top: 7.5rem;
      padding: 4rem 6rem;
      border-radius: 5.4rem;
    }
    @media (max-width: 425px) {
      margin-top: 7.5rem;
      padding: 3.5rem 2.5rem;
      border-radius: 5.2rem;
    }
  }
  .how-it-work__info {
    margin-top: 9.5rem;
    .how-it-work__item {
      display: flex;
      justify-content: space-between;
      margin-top: 10rem;
      &:first-child {
        margin-top: 0;
      }
      .how-it-work__item-img {
        position: relative;
        &.how-it-work__item-bg-bottom::after {
          position: absolute;
          content: "";
          display: block;
          width: 110%;
          height: 60%;
          background: #459edc;
          box-shadow: 1px 3px 27px rgba(69, 158, 220, 0.5);
          border-radius: 8.7rem;
          bottom: -2%;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;
        }
        &.how-it-work__item-bg-top::after {
          position: absolute;
          content: "";
          display: block;
          width: 110%;
          height: 60%;
          background: #459edc;
          box-shadow: 1px 3px 27px rgba(69, 158, 220, 0.5);
          border-radius: 8.7rem;
          top: -2%;
          left: 50%;
          transform: translateX(-50%);
          z-index: 2;
        }
        img {
          width: 100%;
          min-width: 30rem;
          height: auto;
          z-index: 3;
          position: relative;
        }
        @media (max-width: 768px) {
          img {
            min-width: 22.5rem !important;
            width: 100% !important;
          }
        }
        @media (max-width: 425px) {
          &.how-it-work__item-bg-bottom::after {
            width: 100%;
            border-radius: 6rem;
          }
          &.how-it-work__item-bg-top::after {
            width: 100%;
            border-radius: 6rem;
          }
        }
      }
      .how-it-work__item-info {
        margin-top: 7rem;
        max-width: 56rem;
        margin-left: 4.5rem;
        .how-it-work__item-title {
          font-weight: 700;
          font-size: 3.6rem;
          line-height: 4.9rem;
          color: #459edc;
          margin-bottom: 2rem;
          @media (max-width: 768px) {
            font-size: 3.2rem;
            line-height: 4.2rem;
          }
          @media (max-width: 425px) {
            font-size: 2.8rem;
            line-height: 3.6rem;
          }
        }
      }
      &:nth-child(2n + 1) {
        flex-direction: row-reverse;
        .how-it-work__item-info {
          margin-right: 4.5rem;
          margin-left: 0;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column !important;
        align-items: center;
        .how-it-work__item-img {
          width: fit-content;
          img {
            width: auto;
          }
        }
        .how-it-work__item-info {
          margin-left: 0;
        }
        &:nth-child(2n + 1) {
          .how-it-work__item-info {
            margin-right: 0;
          }
        }
      }
    }
  }
}
