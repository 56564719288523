// Burger Menu
.burger-menu {
  display: none;
  position: relative;
  min-width: 4.25rem;
  width: 4.25rem;
  height: 3rem;
  cursor: pointer;
  z-index: 10000;
  div {
    width: 100%;
    height: 4px;
    border-radius: 50px;
    background-color: #459edc;
    position: absolute !important;
    transition: 0.2s;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-child(3) {
      bottom: 0;
    }
  }
  &__active {
    div {
      background-color: #459edc;
    }
    div:nth-child(1) {
      animation: active_burger1 0.35s forwards;
    }
    div:nth-child(2) {
      animation: active_burger2 0.35s forwards;
    }
    div:nth-child(3) {
      animation: active_burger3 0.35s forwards;
    }
    @keyframes active_burger1 {
      0% {
        top: 0;
      }
      50% {
        top: 50%;
        transform: translateY(-50%);
      }
      100% {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    @keyframes active_burger2 {
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    @keyframes active_burger3 {
      0% {
        bottom: 0;
      }
      50% {
        bottom: 50%;
        transform: translateY(50%);
      }
      100% {
        bottom: 50%;
        transform: translateY(50%) rotate(45deg);
      }
    }
  }
  &__unactive {
    div:nth-child(1) {
      animation: unative_burger1 0.35s;
    }
    div:nth-child(2) {
      animation: unative_burger2 0.35s;
    }
    div:nth-child(3) {
      animation: unative_burger3 0.35s;
    }
    @keyframes unative_burger1 {
      0% {
        transform: translateY(-50%) rotate(-45deg);
        top: 50%;
      }
      50% {
        top: 50%;
        transform: translateY(-50%);
      }
      100% {
        top: 0;
      }
    }
    @keyframes unative_burger2 {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @keyframes unative_burger3 {
      0% {
        bottom: 50%;
        transform: translateY(50%) rotate(45deg);
      }
      50% {
        bottom: 50%;
        transform: translateY(50%);
      }
      100% {
        bottom: 0;
      }
    }
  }
  @media (max-width: 1024px) {
    display: block;
  }
}

// Btns
.btn {
  display: inline-block;
  background-color: #459edc;
  padding: 0.8rem 3.3rem 1.2rem;
  border-radius: 10rem;
  color: #fff !important;
  font-size: 2.4rem;
  @media (max-width: 1600px) {
    font-size: 2rem;
    padding: 0.75rem 2.5rem .75rem;
  }
}

.download-link {
  display: block;
  max-width: 28rem;
  margin: 0 auto;
  margin-top: 3.5rem;
  img {
    width: 100%;
    filter: drop-shadow(1px 3px 27px rgba(0, 0, 0, 0.15));
  }
}

.site-text {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.3rem;
  color: #000000;
  @media (max-width: 768px) {
    font-size: 2rem;    
    line-height: 3rem;
  }
  @media (max-width: 425px) {
    font-size: 1.8rem;    
    line-height: 2.6rem;
  }
}

.site-title {
  font-weight: 800;
  font-size: 4.8rem;
  line-height: 6.6rem;
  color: #459EDC;
  @media (max-width: 768px) {
    font-size: 4.2rem !important;
    line-height: 6.2rem !important;
  }
  @media (max-width: 425px) {
    font-size: 3.2rem !important;
    line-height: 5.2rem !important;
  }
}