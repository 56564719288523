.contacts {
  margin-top: 14rem;
  .contacts__inner {
    ul {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      .contacts__item {
        margin: 1rem 0;
        color: #459edc;
        margin-left: 1.5rem;
        @media (max-width: 425px) {
          font-size: 2.2rem;
          a {
            display: block;
            margin: .75rem 0 0 .75rem;
          }
        }
        span {
          color: #000;
          font-weight: 700;
        }
      }
    }
  }
}