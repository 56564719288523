.get-badge {
  margin-top: 12rem;
  .site-title {
    max-width: 96rem;
  }
  .get-badge__info {
    display: flex;
    margin-top: 8.5rem;
    align-items: center;
    .get-badge__info-form {
      max-width: 60rem;
      min-width: 48rem;
      width: 100%;
      .get-badge__info-row {
        display: flex;
        flex-direction: column;
        margin-top: 4.5rem;
        label {
          font-weight: 700;
          font-size: 3.6rem;
          line-height: 4.9rem;
          color: #459edc;
          @media (max-width: 1600px) {
            font-size: 3.2rem;
            line-height: 4.2rem;
          }
        }
        input {
          width: 100%;
          padding: 2.2rem 5.5rem 1.5rem;
          margin-top: 2.2rem;
          background: #efefef;
          border-radius: 10rem;
          font-size: 2.8rem;
          line-height: 3.8rem;
          color: #459edc;
          &::placeholder {
            font-weight: 600;
            font-size: 2.8rem;
            line-height: 3.8rem;
            color: #878787;
          }
          @media (max-width: 1600px) {
            padding: 1.4rem 4rem 1.2rem;
            font-size: 2rem;
            line-height: 3rem;
            &::placeholder {
              font-size: 2rem;
              line-height: 3rem;
            }
          }
        }
        button.btn {
          display: inline-block;
          width: fit-content;
          box-shadow: 1px 3px 27px rgba(69, 158, 220, 0.5);
          border-radius: 10rem;
          padding: 2.2rem 10.5rem 1.5rem;
          transition: 0.15s;
          color: #fff;
          border: 1px solid #459edc;
          &:hover {
            box-shadow: 1px 3px 30px rgba(69, 158, 220, 0.6);
            background-color: #fff;
            color: #459edc !important;
          }
          @media (max-width: 1600px) {
            padding: 1.2rem 8.5rem 1.2rem;
          }
        }
        &.get-badge__info-row--empty {
          input {
            border: 2px solid #ff2121;
            &::placeholder {
              color: #ff2121 !important;
            }
          }
        }
      }
      @media (max-width: 550px) {
        min-width: 0;
        .get-badge__info-row {
          margin-top: 4.5rem;
          label {
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 4.5rem;
            color: #459edc;
          }
          input {
            padding: 2rem 3rem 1.2rem;
            margin-top: 2.2rem;
            font-size: 2.4rem;
            &::placeholder {
              font-size: 2.4rem;
            }
          }
          button.btn {
            padding: 2rem 8rem 1.2rem;
          }
        }
      }
      @media (max-width: 425px) {
        .get-badge__info-row {
          margin-top: 4.5rem;
          label {
            font-weight: 700;
            font-size: 3.2rem;
            line-height: 4.5rem;
            color: #459edc;
          }
          input {
            padding: 2rem 2.5rem 1.2rem;
            margin-top: 1.8rem;
            font-size: 2rem;
            &::placeholder {
              font-size: 2rem;
            }
          }
          button.btn {
            padding: 2rem 8rem 1.2rem;
          }
        }
      }
    }
    .get-badge__info-img {
      margin-left: 6.5rem;
      @media (max-width: 550px) {
        display: none;
      }
    }
    @media (max-width: 550px) {
      margin-top: 5rem;
    }
  }
}
