*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  border: 0;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 10px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  font-family: "Manrope", sans-serif;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
  font-family: inherit;
  background-color: unset;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  color: #03a9f4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}

.burger-menu {
  display: none;
  position: relative;
  min-width: 4.25rem;
  width: 4.25rem;
  height: 3rem;
  cursor: pointer;
  z-index: 10000;
}
.burger-menu div {
  width: 100%;
  height: 4px;
  border-radius: 50px;
  background-color: #459edc;
  position: absolute !important;
  transition: 0.2s;
}
.burger-menu div:nth-child(1) {
  top: 0;
}
.burger-menu div:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.burger-menu div:nth-child(3) {
  bottom: 0;
}
.burger-menu__active div {
  background-color: #459edc;
}
.burger-menu__active div:nth-child(1) {
  animation: active_burger1 0.35s forwards;
}
.burger-menu__active div:nth-child(2) {
  animation: active_burger2 0.35s forwards;
}
.burger-menu__active div:nth-child(3) {
  animation: active_burger3 0.35s forwards;
}
@keyframes active_burger1 {
  0% {
    top: 0;
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  100% {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
}
@keyframes active_burger2 {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes active_burger3 {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 50%;
    transform: translateY(50%);
  }
  100% {
    bottom: 50%;
    transform: translateY(50%) rotate(45deg);
  }
}
.burger-menu__unactive div:nth-child(1) {
  animation: unative_burger1 0.35s;
}
.burger-menu__unactive div:nth-child(2) {
  animation: unative_burger2 0.35s;
}
.burger-menu__unactive div:nth-child(3) {
  animation: unative_burger3 0.35s;
}
@keyframes unative_burger1 {
  0% {
    transform: translateY(-50%) rotate(-45deg);
    top: 50%;
  }
  50% {
    top: 50%;
    transform: translateY(-50%);
  }
  100% {
    top: 0;
  }
}
@keyframes unative_burger2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes unative_burger3 {
  0% {
    bottom: 50%;
    transform: translateY(50%) rotate(45deg);
  }
  50% {
    bottom: 50%;
    transform: translateY(50%);
  }
  100% {
    bottom: 0;
  }
}
@media (max-width: 1024px) {
  .burger-menu {
    display: block;
  }
}

.btn {
  display: inline-block;
  background-color: #459edc;
  padding: 0.8rem 3.3rem 1.2rem;
  border-radius: 10rem;
  color: #fff !important;
  font-size: 2.4rem;
}
@media (max-width: 1600px) {
  .btn {
    font-size: 2rem;
    padding: 0.75rem 2.5rem 0.75rem;
  }
}

.download-link {
  display: block;
  max-width: 28rem;
  margin: 0 auto;
  margin-top: 3.5rem;
}
.download-link img {
  width: 100%;
  filter: drop-shadow(1px 3px 27px rgba(0, 0, 0, 0.15));
}

.site-text {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.3rem;
  color: #000000;
}
@media (max-width: 768px) {
  .site-text {
    font-size: 2rem;
    line-height: 3rem;
  }
}
@media (max-width: 425px) {
  .site-text {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
}

.site-title {
  font-weight: 800;
  font-size: 4.8rem;
  line-height: 6.6rem;
  color: #459EDC;
}
@media (max-width: 768px) {
  .site-title {
    font-size: 4.2rem !important;
    line-height: 6.2rem !important;
  }
}
@media (max-width: 425px) {
  .site-title {
    font-size: 3.2rem !important;
    line-height: 5.2rem !important;
  }
}

header.header {
  width: 100%;
}
header.header .btn {
  border: 2px solid #459edc;
  transition: 0.15s;
}
header.header .btn:hover {
  background-color: #fff;
  color: #459edc !important;
}
header.header .blur-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  z-index: 20;
  display: none;
}
header.header .blur-bg__active {
  display: block;
}
header.header .header__hat {
  z-index: 5;
  background-color: #fff;
  max-width: 136rem;
  margin: 0 auto;
  width: 100%;
  padding: 6.85rem 0;
  padding-right: 22.3rem;
  padding-left: 10%;
  position: relative;
}
header.header .header__hat .header__navbar {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
header.header .header__hat .header__navbar-item {
  font-size: 2.4rem;
}
header.header .header__hat .header__navbar-item a {
  font-weight: 600;
  color: #000;
  transition: 0.15s;
}
header.header .header__hat .header__navbar-item:not(.header__navbar-item-btn) {
  margin: 0 1rem;
}
header.header .header__hat .header__navbar-item:not(.header__navbar-item-btn):not(.site-logo):hover a {
  color: #459edc;
}
header.header .header__hat .header__navbar-item.site-logo a {
  display: block;
  width: 23rem;
  height: auto;
}
header.header .header__hat .header__navbar-item.site-logo a img {
  width: 100%;
  height: auto;
}
header.header .header__hat .header__navbar-item.header__navbar-item-btn {
  position: absolute;
  right: 0;
}
header.header .header__hat .header__navbar-item.header__navbar-item-btn-v {
  position: absolute;
  left: -2rem;
}
header.header .header__hat .header__navbar-item.header__navbar-item-btn-v a {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  transition: color 0.2s, background-color 0.2s;
}
header.header .header__hat .header__navbar-item.header__navbar-item-btn-v a svg {
  transition: fill 0.2s;
  width: auto;
  height: 12px;
  margin-left: 10px;
  fill: #fff;
}
@media screen and (min-width: 1600px) {
  header.header .header__hat .header__navbar-item.header__navbar-item-btn-v {
    left: -5rem;
  }
}
header.header .header__hat .header__navbar-item.header__navbar-item-btn-v:hover a {
  background-color: #fff;
  color: #459edc;
}
header.header .header__hat .header__navbar-item.header__navbar-item-btn-v:hover a svg {
  fill: #459edc;
}
header.header .mobile--header__hat {
  z-index: 5;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 5rem 2.5rem;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
}
header.header .mobile--header__hat .site-logo {
  margin-right: 5rem;
}
header.header .mobile--header__hat .site-logo a {
  display: block;
}
header.header .mobile--header__hat .navbar {
  position: fixed;
  z-index: 30;
  width: 50%;
  height: inherit;
  background-color: #fff;
  right: -50%;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.3s linear;
}
header.header .mobile--header__hat .navbar .navbar-item {
  margin: 1.5rem 0 1.5rem 20%;
}
header.header .mobile--header__hat .navbar .navbar-item a {
  color: #000;
  font-size: 2.2rem;
  font-weight: 600;
  text-transform: uppercase;
}
header.header .mobile--header__hat .navbar .navbar-item:hover a {
  color: #459edc;
}
header.header .mobile--header__hat .navbar.navbar__active {
  right: 0;
}
header.header .mobile--header__hat .navbar .header__navbar-item-btn-v {
  position: relative;
}
header.header .mobile--header__hat .navbar .header__navbar-item-btn-v a {
  display: inline-flex;
  align-items: center;
  font-size: 1.6rem;
  transition: color 0.2s, background-color 0.2s;
}
header.header .mobile--header__hat .navbar .header__navbar-item-btn-v a svg {
  transition: fill 0.2s;
  width: auto;
  height: 12px;
  margin-left: 10px;
  fill: #fff;
}
header.header .mobile--header__hat .navbar .header__navbar-item-btn-v:hover a {
  background-color: #fff;
  color: #459edc;
}
header.header .mobile--header__hat .navbar .header__navbar-item-btn-v:hover a svg {
  fill: #459edc;
}
header.header .mobile--header__hat .right-part {
  margin-right: 5rem;
}
header.header .mobile--header__hat .burger-menu {
  position: relative;
  z-index: 30;
}
header.header .burger-menu__active {
  position: fixed !important;
  top: 6.5rem;
  right: 2.5rem;
  z-index: 10;
}
header.header__fixed .header__hat {
  min-width: 100%;
  max-width: 192rem;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 3rem 24.3rem 3rem 2rem !important;
}
header.header__fixed .header__hat .header__navbar-item.header__navbar-item-btn {
  position: absolute;
  right: 2rem;
}
header.header__fixed .mobile--header__hat {
  min-width: 100%;
  max-width: 192rem;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 1.5rem 2.5rem !important;
}

@media (max-width: 1600px) {
  header.header {
    width: 100%;
  }
  header.header .header__hat {
    padding: 4.5rem 0;
    padding-right: 20rem;
    padding-left: 20rem;
  }
  header.header .header__hat .header__navbar-item {
    font-size: 1.8rem;
  }
  header.header .header__hat .header__navbar-item.site-logo a {
    width: 15rem;
  }
  header.header .header__hat .header__navbar-item.header__navbar-item-btn {
    position: absolute;
  }
  header.header .header__hat .header__navbar-item.header__navbar-item-btn a {
    border: 2px solid #459edc;
    transition: 0.15s;
    font-size: 2rem;
    padding: 0.75rem 2.8rem 0.8rem;
  }
  header.header .header__hat .header__navbar-item.header__navbar-item-btn:hover a {
    background-color: #fff;
    color: #459edc !important;
  }
  header.header .header__title {
    font-size: 5.4rem !important;
  }
}
@media (max-width: 600px) {
  header.header .mobile--header__hat {
    padding: 3.5rem 2rem;
  }
  header.header .mobile--header__hat .site-logo {
    width: 20rem;
  }
  header.header .mobile--header__hat .site-logo img {
    width: 100%;
    height: auto;
  }
  header.header .mobile--header__hat .navbar {
    width: 100%;
    align-items: center;
    right: -100%;
  }
  header.header .mobile--header__hat .navbar .navbar-item {
    margin-left: 0;
  }
  header.header .mobile--header__hat .right-part {
    margin-right: 5rem;
  }
  header.header .mobile--header__hat .burger-menu {
    width: 3.75rem;
    height: 2.75rem;
  }
  header.header .mobile--header__hat .burger-menu div {
    height: 4px;
  }
}
header.header .header__content {
  padding-top: 7rem;
  background-color: #459edc;
  height: calc(100% - 24.5rem);
}
header.header .header__content .header__title {
  max-width: 82.5rem;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  font-size: 6.4rem;
  font-weight: 800;
}
@media (max-width: 768px) {
  header.header .header__content .header__title {
    font-size: 4.8rem !important;
  }
}
@media (max-width: 425px) {
  header.header .header__content .header__title {
    font-size: 3.4rem !important;
  }
}
header.header .header__content .header__undertitle {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.1rem;
  color: #fff;
  max-width: 50rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 3.5rem;
}
@media (max-width: 768px) {
  header.header .header__content .header__undertitle {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
}
@media (max-width: 425px) {
  header.header .header__content .header__undertitle {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
header.header .header__content .header__info {
  margin-top: calc(9rem - 305px + 60px);
  position: relative;
  left: 0;
  bottom: -245px;
}
header.header .header__content .header__info .header__info-bg {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: -25%;
  left: 0;
  z-index: 1;
}
header.header .header__content .header__info .header__img {
  max-width: 35rem;
  width: fit-content;
  height: auto;
  margin: 0 auto;
  z-index: 2;
  position: relative;
}
header.header .header__content .header__info .header__img img {
  width: 100%;
  height: 100%;
}
header.header .header__content .header__info .header__info-blocks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
header.header .header__content .header__info .header__info-blocks .header__info-block {
  position: absolute;
  display: flex;
  max-width: 35.5rem;
  background-color: #fff;
  align-items: center;
  border-radius: 10rem;
  box-shadow: 1px 3px 27px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding-right: 3.5rem;
}
header.header .header__content .header__info .header__info-blocks .header__info-block:nth-child(1) {
  max-width: 39rem;
  top: 180px;
  left: 0;
}
header.header .header__content .header__info .header__info-blocks .header__info-block:nth-child(2) {
  top: 35px;
  right: -85px;
}
header.header .header__content .header__info .header__info-blocks .header__info-block:nth-child(3) {
  bottom: 150px;
  right: 0;
}
header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-img {
  padding: 2.3rem;
  background-color: #fff;
  box-shadow: 1px 3px 27px rgba(0, 0, 0, 0.15);
  border-radius: 10rem;
}
header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-text {
  margin-left: 2rem;
  color: #000;
  font-weight: 800;
  font-size: 1.3rem;
  line-height: 1.8rem;
}
header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-text .header__info-block-title {
  margin-bottom: 1.5rem;
  font-weight: 700;
  font-size: 2.4rem;
  color: #459edc;
}
@media (max-width: 1600px) {
  header.header .header__content .header__info .header__info-blocks {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block {
    position: absolute;
    display: flex;
    max-width: 35.5rem;
    background-color: #fff;
    align-items: center;
    border-radius: 10rem;
    box-shadow: 1px 3px 27px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    padding-right: 3.5rem;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-img {
    padding: 1.5rem;
    background-color: #fff;
    box-shadow: 1px 3px 27px rgba(0, 0, 0, 0.15);
    border-radius: 10rem;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-img img {
    width: 5rem;
    height: 5rem;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-text {
    margin-left: 2rem;
    color: #000;
    font-weight: 800;
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-text .header__info-block-title {
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 2rem;
    color: #459edc;
  }
}
@media (max-width: 1360px) {
  header.header .header__content .header__info .header__info-blocks .header__info-block:nth-child(1) {
    max-width: 39rem;
    top: 180px;
    left: 0;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block:nth-child(2) {
    top: 35px;
    right: 25px;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block:nth-child(3) {
    bottom: 150px;
    right: 40px;
  }
}
@media (max-width: 1024px) {
  header.header .header__content .header__info {
    margin-top: calc(9rem - 305px + 60px);
    position: relative;
    left: 0;
    bottom: -245px;
    display: flex;
    justify-content: center;
  }
  header.header .header__content .header__info .header__info-bg {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  header.header .header__content .header__info .header__img {
    min-width: 25rem;
    width: 100%;
    height: auto;
    margin: 0;
    z-index: 2;
    position: relative;
  }
  header.header .header__content .header__info .header__info-blocks {
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block {
    position: relative;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 1.5rem 0;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-img {
    padding: 1.5rem;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-img img {
    width: 5.5rem;
    height: auto;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-text {
    margin-left: 2rem;
    color: #000;
    font-weight: 800;
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-text .header__info-block-title {
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 2rem;
    color: #459edc;
  }
}
@media (max-width: 768px) {
  header.header .header__content .header__info {
    margin-top: calc(9rem - 650px);
    position: relative;
    left: 0;
    bottom: -570px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  header.header .header__content .header__info .header__info-bg {
    top: 25%;
  }
  header.header .header__content .header__info .header__img {
    width: 100%;
    height: auto;
    margin: 0;
    z-index: 2;
    position: relative;
  }
  header.header .header__content .header__info .header__img img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 425px) {
  header.header .header__content .header__info {
    margin-top: calc(9rem - 650px - 30px);
    bottom: -620px;
  }
  header.header .header__content .header__info .header__info-blocks {
    position: relative;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block {
    position: relative;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    margin: 1.5rem 0;
    padding: 0.45rem 0.5rem 0.45rem 0;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-img {
    padding: 1rem;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-img img {
    width: 3.5rem;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-text {
    margin-left: 1rem;
    color: #000;
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  header.header .header__content .header__info .header__info-blocks .header__info-block .header__info-block-text .header__info-block-title {
    margin-bottom: 1.2rem;
    font-weight: 700;
    font-size: 1.6rem !important;
    color: #459edc;
  }
}

body {
  width: 100%;
  min-width: 32rem;
  overflow-x: hidden;
}

.wrapper {
  overflow-x: hidden;
  max-width: 192rem;
  margin: 0 auto;
  width: 100%;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 2rem;
}
@media (max-width: 425px) {
  .container {
    padding: 0 1rem;
  }
}

.main {
  position: relative;
  margin-top: 32rem;
  background-color: #fff;
  z-index: 2;
}
@media (max-width: 768px) {
  .main {
    margin-top: 65rem;
  }
}

.header__underblock {
  max-width: 56rem;
  margin: 0 auto;
}
.header__underblock .header__underblock-text {
  text-align: center;
}
.header__underblock .header__underblock-text img {
  width: 10.8rem;
  height: auto;
  vertical-align: middle;
  margin: 0 0.75rem;
}

.how-it-work {
  margin-top: 10rem;
}
.how-it-work .how-it-work__text {
  margin-top: 9.5rem;
  text-align: center;
  padding: 4.4rem 7.2rem;
  background: #ffffff;
  box-shadow: 0px 6px 54px rgba(218, 225, 231, 0.53);
  border-radius: 5.7rem;
}
.how-it-work .how-it-work__text img {
  width: 10.8rem;
  height: auto;
  vertical-align: middle;
  margin: 0 0.75rem;
}
@media (max-width: 768px) {
  .how-it-work .how-it-work__text {
    margin-top: 7.5rem;
    padding: 4rem 6rem;
    border-radius: 5.4rem;
  }
}
@media (max-width: 425px) {
  .how-it-work .how-it-work__text {
    margin-top: 7.5rem;
    padding: 3.5rem 2.5rem;
    border-radius: 5.2rem;
  }
}
.how-it-work .how-it-work__info {
  margin-top: 9.5rem;
}
.how-it-work .how-it-work__info .how-it-work__item {
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
}
.how-it-work .how-it-work__info .how-it-work__item:first-child {
  margin-top: 0;
}
.how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-img {
  position: relative;
}
.how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-img.how-it-work__item-bg-bottom::after {
  position: absolute;
  content: "";
  display: block;
  width: 110%;
  height: 60%;
  background: #459edc;
  box-shadow: 1px 3px 27px rgba(69, 158, 220, 0.5);
  border-radius: 8.7rem;
  bottom: -2%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-img.how-it-work__item-bg-top::after {
  position: absolute;
  content: "";
  display: block;
  width: 110%;
  height: 60%;
  background: #459edc;
  box-shadow: 1px 3px 27px rgba(69, 158, 220, 0.5);
  border-radius: 8.7rem;
  top: -2%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-img img {
  width: 100%;
  min-width: 30rem;
  height: auto;
  z-index: 3;
  position: relative;
}
@media (max-width: 768px) {
  .how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-img img {
    min-width: 22.5rem !important;
    width: 100% !important;
  }
}
@media (max-width: 425px) {
  .how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-img.how-it-work__item-bg-bottom::after {
    width: 100%;
    border-radius: 6rem;
  }
  .how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-img.how-it-work__item-bg-top::after {
    width: 100%;
    border-radius: 6rem;
  }
}
.how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-info {
  margin-top: 7rem;
  max-width: 56rem;
  margin-left: 4.5rem;
}
.how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-info .how-it-work__item-title {
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4.9rem;
  color: #459edc;
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-info .how-it-work__item-title {
    font-size: 3.2rem;
    line-height: 4.2rem;
  }
}
@media (max-width: 425px) {
  .how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-info .how-it-work__item-title {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }
}
.how-it-work .how-it-work__info .how-it-work__item:nth-child(2n+1) {
  flex-direction: row-reverse;
}
.how-it-work .how-it-work__info .how-it-work__item:nth-child(2n+1) .how-it-work__item-info {
  margin-right: 4.5rem;
  margin-left: 0;
}
@media (max-width: 768px) {
  .how-it-work .how-it-work__info .how-it-work__item {
    flex-direction: column !important;
    align-items: center;
  }
  .how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-img {
    width: fit-content;
  }
  .how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-img img {
    width: auto;
  }
  .how-it-work .how-it-work__info .how-it-work__item .how-it-work__item-info {
    margin-left: 0;
  }
  .how-it-work .how-it-work__info .how-it-work__item:nth-child(2n+1) .how-it-work__item-info {
    margin-right: 0;
  }
}

.what-for {
  margin-top: 10rem;
}
.what-for .what-for__list {
  display: flex;
  margin-top: 7rem;
  flex-wrap: wrap;
  justify-content: center;
}
.what-for .what-for__list .what-for__item {
  display: flex;
  align-items: center;
  max-width: 42rem;
  background: #ffffff;
  box-shadow: 0px 6px 54px rgba(218, 225, 231, 0.53);
  padding: 2.2rem 3.1rem 3.8rem;
  border-radius: 6rem;
  margin: 1.5rem 0;
  margin-right: 6.4rem;
}
.what-for .what-for__list .what-for__item:nth-child(2n) {
  margin-right: 0;
  margin-left: 6.4rem;
}
.what-for .what-for__list .what-for__item .what-for__item-count {
  font-weight: 800;
  font-size: 6.4rem;
  line-height: 8.7rem;
  color: #459edc;
  margin-right: 2.5rem;
}
@media (max-width: 425px) {
  .what-for .what-for__list .what-for__item .what-for__item-count {
    margin-right: 1.5rem;
    font-size: 5rem;
    line-height: 5.7rem;
  }
}
@media (max-width: 1024px) {
  .what-for .what-for__list .what-for__item {
    margin: 1.5rem 0rem;
    margin-right: 1.5rem;
  }
  .what-for .what-for__list .what-for__item:nth-child(2n) {
    margin-right: 0;
    margin-left: 1.5rem;
  }
}
@media (max-width: 925px) {
  .what-for .what-for__list .what-for__item {
    margin: 1.5rem 0rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 425px) {
  .what-for .what-for__list .what-for__item {
    border-radius: 4.5rem;
  }
}
@media (max-width: 925px) {
  .what-for .what-for__list {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 425px) {
  .what-for .what-for__list {
    margin-top: 5rem;
  }
}

.try-it {
  margin-top: 8.5rem;
  background-color: #459edc;
  padding: 10.5rem 0;
}
.try-it .site-title {
  text-align: center;
  color: #fff;
}
.try-it .try-it__text {
  text-align: center;
  color: #fff;
  margin: 0 auto;
  margin-top: 7rem;
  max-width: 56rem;
}
.try-it .try-it__text img {
  width: 10.8rem;
  height: auto;
  vertical-align: middle;
  margin: 0 0.75rem;
}
.try-it .download-link {
  display: block;
  max-width: 28rem;
  margin: 0 auto;
  margin-top: 3.5rem;
}

.get-badge {
  margin-top: 12rem;
}
.get-badge .site-title {
  max-width: 96rem;
}
.get-badge .get-badge__info {
  display: flex;
  margin-top: 8.5rem;
  align-items: center;
}
.get-badge .get-badge__info .get-badge__info-form {
  max-width: 60rem;
  min-width: 48rem;
  width: 100%;
}
.get-badge .get-badge__info .get-badge__info-form .get-badge__info-row {
  display: flex;
  flex-direction: column;
  margin-top: 4.5rem;
}
.get-badge .get-badge__info .get-badge__info-form .get-badge__info-row label {
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4.9rem;
  color: #459edc;
}
@media (max-width: 1600px) {
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row label {
    font-size: 3.2rem;
    line-height: 4.2rem;
  }
}
.get-badge .get-badge__info .get-badge__info-form .get-badge__info-row input {
  width: 100%;
  padding: 2.2rem 5.5rem 1.5rem;
  margin-top: 2.2rem;
  background: #efefef;
  border-radius: 10rem;
  font-size: 2.8rem;
  line-height: 3.8rem;
  color: #459edc;
}
.get-badge .get-badge__info .get-badge__info-form .get-badge__info-row input::placeholder {
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 3.8rem;
  color: #878787;
}
@media (max-width: 1600px) {
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row input {
    padding: 1.4rem 4rem 1.2rem;
    font-size: 2rem;
    line-height: 3rem;
  }
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row input::placeholder {
    font-size: 2rem;
    line-height: 3rem;
  }
}
.get-badge .get-badge__info .get-badge__info-form .get-badge__info-row button.btn {
  display: inline-block;
  width: fit-content;
  box-shadow: 1px 3px 27px rgba(69, 158, 220, 0.5);
  border-radius: 10rem;
  padding: 2.2rem 10.5rem 1.5rem;
  transition: 0.15s;
  color: #fff;
  border: 1px solid #459edc;
}
.get-badge .get-badge__info .get-badge__info-form .get-badge__info-row button.btn:hover {
  box-shadow: 1px 3px 30px rgba(69, 158, 220, 0.6);
  background-color: #fff;
  color: #459edc !important;
}
@media (max-width: 1600px) {
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row button.btn {
    padding: 1.2rem 8.5rem 1.2rem;
  }
}
.get-badge .get-badge__info .get-badge__info-form .get-badge__info-row.get-badge__info-row--empty input {
  border: 2px solid #ff2121;
}
.get-badge .get-badge__info .get-badge__info-form .get-badge__info-row.get-badge__info-row--empty input::placeholder {
  color: #ff2121 !important;
}
@media (max-width: 550px) {
  .get-badge .get-badge__info .get-badge__info-form {
    min-width: 0;
  }
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row {
    margin-top: 4.5rem;
  }
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row label {
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 4.5rem;
    color: #459edc;
  }
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row input {
    padding: 2rem 3rem 1.2rem;
    margin-top: 2.2rem;
    font-size: 2.4rem;
  }
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row input::placeholder {
    font-size: 2.4rem;
  }
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row button.btn {
    padding: 2rem 8rem 1.2rem;
  }
}
@media (max-width: 425px) {
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row {
    margin-top: 4.5rem;
  }
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row label {
    font-weight: 700;
    font-size: 3.2rem;
    line-height: 4.5rem;
    color: #459edc;
  }
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row input {
    padding: 2rem 2.5rem 1.2rem;
    margin-top: 1.8rem;
    font-size: 2rem;
  }
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row input::placeholder {
    font-size: 2rem;
  }
  .get-badge .get-badge__info .get-badge__info-form .get-badge__info-row button.btn {
    padding: 2rem 8rem 1.2rem;
  }
}
.get-badge .get-badge__info .get-badge__info-img {
  margin-left: 6.5rem;
}
@media (max-width: 550px) {
  .get-badge .get-badge__info .get-badge__info-img {
    display: none;
  }
}
@media (max-width: 550px) {
  .get-badge .get-badge__info {
    margin-top: 5rem;
  }
}

.contacts {
  margin-top: 14rem;
}
.contacts .contacts__inner ul {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.contacts .contacts__inner ul .contacts__item {
  margin: 1rem 0;
  color: #459edc;
  margin-left: 1.5rem;
}
@media (max-width: 425px) {
  .contacts .contacts__inner ul .contacts__item {
    font-size: 2.2rem;
  }
  .contacts .contacts__inner ul .contacts__item a {
    display: block;
    margin: 0.75rem 0 0 0.75rem;
  }
}
.contacts .contacts__inner ul .contacts__item span {
  color: #000;
  font-weight: 700;
}

.variants {
  margin-top: 10rem;
}
.variants .variants__info {
  position: relative;
}
.variants .site-title {
  max-width: 60rem;
}
.variants .variants__img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  width: 51.8rem;
  height: 51.8rem;
  margin: 0 auto;
  margin-top: 10rem;
  position: relative;
}
@media (max-width: 768px) {
  .variants .variants__img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.variants .variants__img::after {
  content: "";
  display: block;
  position: absolute;
  border: 3px solid #459edc;
  width: 100%;
  height: 100%;
  border-radius: 100rem;
}
@media (max-width: 768px) {
  .variants .variants__img::after {
    padding: 20rem 2.2rem;
  }
}
.variants .variants__img img {
  width: fit-content;
  height: auto;
}
.variants .variants__list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.variants .variants__list .variants__item {
  position: absolute;
}
.variants .variants__list .variants__item div {
  background: #fff;
  box-shadow: 0px 6px 54px rgba(218, 225, 231, 0.53);
  padding: 2.5rem 3.5rem;
  border-radius: 3rem;
}
.variants .variants__list .variants__item div span {
  color: #459edc;
}
.variants .variants__list .variants__item:nth-child(1) {
  top: 35px;
  left: 100px;
  max-width: 30rem;
}
.variants .variants__list .variants__item:nth-child(2) {
  top: 60px;
  right: 45px;
  max-width: 35rem;
  z-index: 3;
  background: #fff;
  padding: 1rem 1.5rem;
  border-radius: 3rem;
}
.variants .variants__list .variants__item:nth-child(3) {
  bottom: 45px;
  left: 225px;
  max-width: 25rem;
}
.variants .variants__list .variants__item:nth-child(4) {
  right: 0;
  bottom: 0;
  max-width: 50rem;
}
@media (max-width: 1024px) {
  .variants .variants__list .variants__item div {
    padding: 1.5rem 2.5rem;
    border-radius: 2rem;
    font-size: 2rem;
    line-height: 3rem;
  }
  .variants .variants__list .variants__item:nth-child(1) {
    top: 45px;
    left: 0;
    max-width: 30rem;
  }
  .variants .variants__list .variants__item:nth-child(2) {
    top: 55px;
    right: 0;
    max-width: 35rem;
    z-index: 3;
    background: #fff;
    padding: 1rem 1.5rem;
    border-radius: 3rem;
  }
  .variants .variants__list .variants__item:nth-child(3) {
    bottom: 75px;
    left: 45px;
    max-width: 25rem;
  }
  .variants .variants__list .variants__item:nth-child(4) {
    right: 0;
    bottom: 0;
    max-width: 50rem;
  }
}
@media (max-width: 768px) {
  .variants .variants__list .variants__item {
    background-color: transparent;
    position: relative;
    padding: 0;
    margin: 1.5rem 0;
  }
  .variants .variants__list .variants__item div {
    padding: 1.5rem 2.5rem;
    border-radius: 2rem;
  }
  .variants .variants__list .variants__item:nth-child(1) {
    top: 0;
    left: 0;
    max-width: fit-content;
  }
  .variants .variants__list .variants__item:nth-child(2) {
    max-width: fit-content;
    top: 0;
    right: 0;
    background: transparent;
    padding: 0;
    border-radius: 3rem;
  }
  .variants .variants__list .variants__item:nth-child(3) {
    max-width: fit-content;
    bottom: 0;
    left: 0;
  }
  .variants .variants__list .variants__item:nth-child(4) {
    max-width: fit-content;
    right: 0;
    bottom: 0;
  }
}
@media (max-width: 768px) {
  .variants .variants__list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-top: 5rem;
  }
}
@supports (-webkit-appearance:none){

}

 @media (max-width: 1024px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1600px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 768px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 425px){

@supports (-webkit-appearance:none){

}

}

 @media screen and (min-width: 1600px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 600px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 1360px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 925px){

@supports (-webkit-appearance:none){

}

}

 @media (max-width: 550px){

@supports (-webkit-appearance:none){

}

}
