.what-for {
  margin-top: 10rem;
  .what-for__list {
    display: flex;
    margin-top: 7rem;
    flex-wrap: wrap;
    justify-content: center;
    .what-for__item {
      display: flex;
      align-items: center;
      max-width: 42rem;
      background: #ffffff;
      box-shadow: 0px 6px 54px rgba(218, 225, 231, 0.53);
      padding: 2.2rem 3.1rem 3.8rem;
      border-radius: 6rem;
      margin: 1.5rem 0;
      margin-right: 6.4rem;
      &:nth-child(2n) {
        margin-right: 0;
        margin-left: 6.4rem;
      }
      .what-for__item-count {
        font-weight: 800;
        font-size: 6.4rem;
        line-height: 8.7rem;
        color: #459edc;
        margin-right: 2.5rem;
        @media (max-width: 425px) {
          margin-right: 1.5rem;
          font-size: 5rem;
          line-height: 5.7rem;
        }
      }
      @media (max-width: 1024px) {
        margin: 1.5rem 0rem;
        margin-right: 1.5rem;
        &:nth-child(2n) {
          margin-right: 0;
          margin-left: 1.5rem;
        }
      }
      @media (max-width: 925px) {
        margin: 1.5rem 0rem;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      @media (max-width: 425px) {
        border-radius: 4.5rem;
      }
    }
    @media (max-width: 925px) {
      flex-direction: column;
      align-items: center;
    }
    @media (max-width: 425px) {
      margin-top: 5rem;
    }
  }
}
