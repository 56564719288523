.variants {
  margin-top: 10rem;
  .variants__info {
    position: relative;
  }
  .site-title {
    max-width: 60rem;
  }
  .variants__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    width: 51.8rem;
    height: 51.8rem;
    margin: 0 auto;
    margin-top: 10rem;
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      border: 3px solid #459edc;
      width: 100%;
      height: 100%;
      border-radius: 100rem;
      @media (max-width: 768px) {
        padding: 20rem 2.2rem;
      }
    }
    img {
      width: fit-content;
      height: auto;
    }
  }
  .variants__list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .variants__item {
      position: absolute;
      div {
        background: #fff;
        box-shadow: 0px 6px 54px rgba(218, 225, 231, 0.53);
        padding: 2.5rem 3.5rem;
        border-radius: 3rem;
        span {
          color: #459edc;
        }
      }
      &:nth-child(1) {
        top: 35px;
        left: 100px;
        max-width: 30rem;
      }
      &:nth-child(2) {
        top: 60px;
        right: 45px;
        max-width: 35rem;
        z-index: 3;
        background: #fff;
        padding: 1rem 1.5rem;
        border-radius: 3rem;
      }
      &:nth-child(3) {
        bottom: 45px;
        left: 225px;
        max-width: 25rem;
      }
      &:nth-child(4) {
        right: 0;
        bottom: 0;
        max-width: 50rem;
      }
      @media (max-width: 1024px) {
        div {
          padding: 1.5rem 2.5rem;
          border-radius: 2rem;
          font-size: 2rem;
          line-height: 3rem;
        }
        &:nth-child(1) {
          top: 45px;
          left: 0;
          max-width: 30rem;
        }
        &:nth-child(2) {
          top: 55px;
          right: 0;
          max-width: 35rem;
          z-index: 3;
          background: #fff;
          padding: 1rem 1.5rem;
          border-radius: 3rem;
        }
        &:nth-child(3) {
          bottom: 75px;
          left: 45px;
          max-width: 25rem;
        }
        &:nth-child(4) {
          right: 0;
          bottom: 0;
          max-width: 50rem;
        }
      }
      @media (max-width: 768px) {
        background-color: transparent;
        position: relative;
        padding: 0;
        margin: 1.5rem 0;
        div {
          padding: 1.5rem 2.5rem;
          border-radius: 2rem;
        }
        &:nth-child(1) {
          top: 0;
          left: 0;
          max-width: fit-content;
        }
        &:nth-child(2) {
          max-width: fit-content;
          top: 0;
          right: 0;
          background: transparent;
          padding: 0;
          border-radius: 3rem;
        }
        &:nth-child(3) {
          max-width: fit-content;
          bottom: 0;
          left: 0;
        }
        &:nth-child(4) {
          max-width: fit-content;
          right: 0;
          bottom: 0;
        }
      }
      @media (max-width: 768px) {}
    }
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      margin-top: 5rem;
    }
  }
}
