header.header {
  .header__content {
    padding-top: 7rem;
    background-color: #459edc;
    height: calc(100% - 24.5rem);
    .header__title {
      max-width: 82.5rem;
      margin: 0 auto;
      color: #fff;
      text-align: center;
      font-size: 6.4rem;
      font-weight: 800;
      @media (max-width: 768px) {
        font-size: 4.8rem !important;
      }
      @media (max-width: 425px) {
        font-size: 3.4rem !important;
      }
    }
    .header__undertitle {
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3.1rem;
      color: #fff;
      max-width: 50rem;
      text-align: center;
      margin: 0 auto;
      margin-top: 3.5rem;
      @media (max-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
      @media (max-width: 425px) {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
    .header__info {
      margin-top: calc(9rem - 305px + 60px);
      position: relative;
      left: 0;
      bottom: -305px + 60px;
      .header__info-bg {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: -25%;
        left: 0;
        z-index: 1;
      }
      .header__img {
        max-width: 35rem;
        width: fit-content;
        height: auto;
        margin: 0 auto;
        z-index: 2;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .header__info-blocks {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .header__info-block {
          position: absolute;
          display: flex;
          max-width: 35.5rem;
          background-color: #fff;
          align-items: center;
          border-radius: 10rem;
          box-shadow: 1px 3px 27px rgba(0, 0, 0, 0.15);
          overflow: hidden;
          padding-right: 3.5rem;
          &:nth-child(1) {
            max-width: 39rem;
            top: 180px;
            left: 0;
          }
          &:nth-child(2) {
            top: 35px;
            right: -85px;
          }
          &:nth-child(3) {
            bottom: 150px;
            right: 0;
          }
          .header__info-block-img {
            padding: 2.3rem;
            background-color: #fff;
            box-shadow: 1px 3px 27px rgba(0, 0, 0, 0.15);
            border-radius: 10rem;
          }
          .header__info-block-text {
            margin-left: 2rem;
            color: #000;
            font-weight: 800;
            font-size: 1.3rem;
            line-height: 1.8rem;
            .header__info-block-title {
              margin-bottom: 1.5rem;
              font-weight: 700;
              font-size: 2.4rem;
              color: #459edc;
            }
          }
        }
      }

      @media (max-width: 1600px) {
        .header__info-blocks {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .header__info-block {
            position: absolute;
            display: flex;
            max-width: 35.5rem;
            background-color: #fff;
            align-items: center;
            border-radius: 10rem;
            box-shadow: 1px 3px 27px rgba(0, 0, 0, 0.15);
            overflow: hidden;
            padding-right: 3.5rem;
            .header__info-block-img {
              padding: 1.5rem;
              background-color: #fff;
              box-shadow: 1px 3px 27px rgba(0, 0, 0, 0.15);
              border-radius: 10rem;
              img {
                width: 5rem;
                height: 5rem;
              }
            }
            .header__info-block-text {
              margin-left: 2rem;
              color: #000;
              font-weight: 800;
              font-size: 1.3rem;
              line-height: 1.8rem;
              .header__info-block-title {
                margin-bottom: .5rem;
                font-weight: 700;
                font-size: 2rem;
                color: #459edc;
              }
            }
          }
        }
      }

      @media (max-width: 1360px) {
        .header__info-blocks {
          .header__info-block {
            &:nth-child(1) {
              max-width: 39rem;
              top: 180px;
              left: 0;
            }
            &:nth-child(2) {
              top: 35px;
              right: 25px;
            }
            &:nth-child(3) {
              bottom: 150px;
              right: 40px;
            }
          }
        }
      }

      @media (max-width: 1024px) {
        margin-top: calc(9rem - 305px + 60px);
        position: relative;
        left: 0;
        bottom: -305px + 60px;
        display: flex;
        justify-content: center;
        .header__info-bg {
          width: 100%;
          height: auto;
          position: absolute;
          bottom: 0%;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
        }
        .header__img {
          min-width: 25rem;
          width: 100%;
          height: auto;
          margin: 0;
          z-index: 2;
          position: relative;
        }
        .header__info-blocks {
          position: relative;
          width: auto;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .header__info-block {
            position: relative;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            margin: 1.5rem 0;
            .header__info-block-img {
              padding: 1.5rem;
              img {
                width: 5.5rem;
                height: auto;
              }
            }
            .header__info-block-text {
              margin-left: 2rem;
              color: #000;
              font-weight: 800;
              font-size: 1.3rem;
              line-height: 1.8rem;
              .header__info-block-title {
                margin-bottom: .5rem;
                font-weight: 700;
                font-size: 2rem;
                color: #459edc;
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        margin-top: calc(9rem - 650px);
        position: relative;
        left: 0;
        bottom: -650px + 80px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .header__info-bg {
          top: 25%;
        }
        .header__img {
          width: 100%;
          height: auto;
          margin: 0;
          z-index: 2;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      @media (max-width: 425px) {
        margin-top: calc(9rem - 650px - 30px);
        bottom: -650px + 30px;
        .header__info-blocks {
          position: relative;
          width: auto;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .header__info-block {
            position: relative;
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            margin: 1.5rem 0;
            padding: 0.45rem 0.5rem 0.45rem 0;
            .header__info-block-img {
              padding: 1rem;
              img {
                width: 3.5rem;
              }
            }
            .header__info-block-text {
              margin-left: 1rem;
              color: #000;
              font-weight: 800;
              font-size: 1.2rem;
              line-height: 1.6rem;
              .header__info-block-title {
                margin-bottom: 1.2rem;
                font-weight: 700;
                font-size: 1.6rem !important;
                color: #459edc;
              }
            }
          }
        }
      }
    }
  }
}
