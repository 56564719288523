.try-it {
  margin-top: 8.5rem;
  background-color: #459edc;
  padding: 10.5rem 0;
  .site-title {
    text-align: center;
    color: #fff;
  }
  .try-it__text {
    text-align: center;
    color: #fff;
    margin: 0 auto;
    margin-top: 7rem;
    max-width: 56rem;
    img {
      width: 10.8rem;
      height: auto;
      vertical-align: middle;
      margin: 0 0.75rem;
    }
  }
  .download-link {
    display: block;
    max-width: 28rem;
    margin: 0 auto;
    margin-top: 3.5rem;
  }
}
