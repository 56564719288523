header.header {
  width: 100%;

  .btn {
    border: 2px solid #459edc;
    transition: 0.15s;
    &:hover {
      background-color: #fff;
      color: #459edc !important;
    }
  }

  .blur-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(#000, 0.2);
    backdrop-filter: blur(5px);
    z-index: 20;
    display: none;
    &__active {
      display: block;
    }
  }

  .header__hat {
    z-index: 5;
    background-color: #fff;
    max-width: 136rem;
    margin: 0 auto;
    width: 100%;
    padding: 6.85rem 0;
    padding-right: 22.3rem;
    padding-left: 10%;
    position: relative;
    .header__navbar {
      justify-content: space-between;
      display: flex;
      align-items: center;
    }
    .header__navbar-item {
      font-size: 2.4rem;
      a {
        font-weight: 600;
        color: #000;
        transition: 0.15s;
      }

      &:not(.header__navbar-item-btn) {
        margin: 0 1rem;
        &:not(.site-logo):hover {
          a {
            color: #459edc;
          }
        }
      }
      &.site-logo {
        a {
          display: block;
          width: 23rem;
          height: auto;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      &.header__navbar-item-btn {
        position: absolute;
        right: 0;
      }
      &.header__navbar-item-btn-v {
        a {
          display: flex;
          align-items: center;
          font-size: 1.6rem;
          transition: color .2s, background-color .2s;
          svg {
            transition: fill .2s;
            width: auto;
            height: 12px;
            margin-left: 10px;
            fill: #fff;
          }
        }
        position: absolute;
        left: -2rem;
        @media screen and (min-width: 1600px) {
          left: -5rem;
        }
        &:hover {
          a {
            background-color: #fff;
            color: #459edc;
            svg {
              fill: #459edc;
            }
          }
        }
      }
    }
  }

  .mobile--header__hat {
    z-index: 5;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    padding: 5rem 2.5rem;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    .site-logo {
      margin-right: 5rem;
      a {
        display: block;
      }
    }
    .navbar {
      position: fixed;
      z-index: 30;
      width: 50%;
      height: inherit;
      background-color: #fff;
      right: -50%;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: 0.3s linear;
      .navbar-item {
        margin: 1.5rem 0 1.5rem 20%;
        a {
          color: #000;
          font-size: 2.2rem;
          font-weight: 600;
          text-transform: uppercase;
        }
        &:hover {
          a {
            color: #459edc;
          }
        }
      }
      &.navbar__active {
        right: 0;
      }
      .header__navbar-item-btn-v {
        a {
          display: inline-flex;
          align-items: center;
          font-size: 1.6rem;
          transition: color .2s, background-color .2s;
          svg {
            transition: fill .2s;
            width: auto;
            height: 12px;
            margin-left: 10px;
            fill: #fff;
          }
        }
        position: relative;
        &:hover {
          a {
            background-color: #fff;
            color: #459edc;
            svg {
              fill: #459edc;
            }
          }
        }
      }
    }
    .right-part {
      margin-right: 5rem;
    }
    .burger-menu {
      position: relative;
      z-index: 30;
    }
  }

  .burger-menu__active {
    position: fixed !important;
    top: 6.5rem;
    right: 2.5rem;
    z-index: 10;
  }

  &__fixed {
    .header__hat {
      min-width: 100%;
      max-width: 192rem;
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      padding: 3rem 24.3rem 3rem 2rem !important;
      .header__navbar-item {
        &.header__navbar-item-btn {
          position: absolute;
          right: 2rem;
        }
      }
    }
    .mobile--header__hat {
      min-width: 100%;
      max-width: 192rem;
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      padding: 1.5rem 2.5rem !important;
    }
  }
}

@media (max-width: 1600px) {
  header.header {
    width: 100%;
    .header__hat {
      padding: 4.5rem 0;
      padding-right: 20rem;
      padding-left: 20rem;
      .header__navbar-item {
        font-size: 1.8rem;
        &.site-logo {
          a {
            width: 15rem;
          }
        }
        &.header__navbar-item-btn {
          position: absolute;
          a {
            border: 2px solid #459edc;
            transition: 0.15s;
            font-size: 2rem;
            padding: 0.75rem 2.8rem 0.8rem;
          }
          &:hover {
            a {
              background-color: #fff;
              color: #459edc !important;
            }
          }
        }
      }
    }
    .header__title {
      font-size: 5.4rem !important;
    }
  }
}
@media (max-width: 600px) {
  header.header {
    .mobile--header__hat {
      padding: 3.5rem 2rem;
      .site-logo {
        width: 20rem;
        img {
          width: 100%;
          height: auto;
        }
      }
      .navbar {
        width: 100%;
        align-items: center;
        right: -100%;
        .navbar-item {
          margin-left: 0;
        }
      }
      .right-part {
        margin-right: 5rem;
      }
      .burger-menu {
        width: 3.75rem;
        height: 2.75rem;
        div {
          height: 4px;
        }
      }
    }
  }
}

@import "./header-content";
