.header__underblock {
  max-width: 56rem;
  margin: 0 auto;
  .header__underblock-text {
    text-align: center;
    img {
      width: 10.8rem;
      height: auto;
      vertical-align: middle;
      margin: 0 .75rem;
    }
  }
}
